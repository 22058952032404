@charset "utf-8";

// ------------ FOOTER ------------*/

footer {
	width: 100%;
	padding: 35px 0;
	margin: auto;
	background-color: $main-color;
	border-top: 1px solid #fff;
	text-align: center;

	// ドキュメントリンク
	section.copyright {

		.doc_links {

			li {
				display: inline-block;
				padding-bottom: 0.5em;

				&:not(:first-of-type) {
					padding-left: 2em;
				}
			}

			a {
				color: #fff;
			}
		}

		p {
			@extend .font_size_12;
			color: #fff;
		}
	}

	&.form_footer {
		max-width: 500px;
		border-radius: 0 0 10px 10px;

		// ドキュメントリンク
		section.copyright {

			.doc_links {
				text-align: left;

				li {
					display: block;
					padding-left: 2em;
				}

				a {
					position: relative;
					padding-left: 1em;
					font-size: 14px;

					&::before {
						content: "";
						background-image: url(../img/arrow/arrow_right_w.svg);
						width: 8px;
						height: 12px;
						background-size: contain;
						background-repeat: no-repeat;
						position: absolute;
						top: 50%;
						left: 0;
						transform: translateY(-50%);
					}
				}
			}
		}
	}
}

.form_footer_wrap {
	padding-bottom: 30px;
}