@charset "utf-8";

// ------------ BASS ------------

html,
body {
	width: 100%;
	//height: 100%;

	// PC表示は幅を最低限固定
	min-width: 1000px;
	margin-left: auto;
	margin-right: auto;

	@include mq() {
		min-width: inherit;
	}
}

body {
	font-family: $base-font-family;
	color: $base-font-color;
	@extend .font_size_16;
	font-weight: 500;
}

div#container {
	width: 100%;
	// min-height: 100vh;
}

// 選択テキストのカラー
::selection {
	background: $main-color;
	color: #FFF;
}

::-moz-selection {
	background: $main-color;
	color: #FFF;
}


// ------------ BASS STYLE ------------


// リンク設定
a {
	@include link-color($base-font-color);
	transition: background-color 0.4s ease, color 0.4s ease, opacity 0.4s ease;

	img {
		transition: opacity 0.4s ease;
	}

	&:hover {
		opacity: 0.8;

		img {
			opacity: 0.8;
		}
	}
}


// TABLE LAYOUT
table {
	width: 100%;
	border-collapse: separate;
	border-spacing: 0;

	&.tb_block {

		&,
		thead,
		tbody,
		tfoot,
		tr {
			display: block;
			width: 100%;
		}

		tr {

			th,
			td {
				display: block;
				width: 100%;
			}
		}


	}

	// table用クラス：~568pxでは縦1列表示にする
	&.sp_block {

		&,
		thead,
		tbody,
		tfoot,
		tr {
			display: block;
			width: 100%;
		}

		tr {

			th,
			td {
				display: block;
				width: 100%;
			}
		}


	}

}

picture {
	display: block;
}

br {
	line-height: inherit;
}


// 斜線
hr {
	height: 1px;
	clear: both;
	border: none;
	border-top: $border-color 1px solid;
	color: #FFF;
}



// ------------ PARTS STYLE ------------

// clearfix
%clearfix {
	zoom: 1;

	&:after {
		display: block;
		content: "";
		clear: both;
	}
}


// ブロック要素のリンク
%a_block {
	width: 100%;
	height: 100%;
	display: block;
}


// ベースのコンテンツ幅
.wrapper {
	width: 95%;
	max-width: 1200px;
	margin: auto;
	position: relative;

	// clearfix
	@extend %clearfix;

}


// リンクエフェクト
.transitions {
	transition: all 0.4s ease;
	-webkit-transition: all 0.4s ease;
	-moz-transition: all 0.4s ease;
	-o-transition: all 0.4s ease;

	@include mq() {
		transition: all 0s ease;
		-webkit-transition: all 0s ease;
		-moz-transition: all 0s ease;
		-o-transition: all 0s ease;
	}
}

// モーションエフェクト
.all_transitions {
	transition: all 0.4s ease;
	-webkit-transition: all 0.4s ease;
	-moz-transition: all 0.4s ease;
	-o-transition: all 0.4s ease;
}

// 上下中央に配置
.vertical {
	position: absolute;
	top: 50%;
	-webkit-transform: translate(0%, -50%);
	transform: translate(0%, -50%);
}

//高さ指定して中心でトリミング
.obj_image {
	object-fit: cover;
	font-family: 'object-fit: cover;'
}

// TITLE
%ttl {
	margin: 0 auto 10px auto;
	font-weight: bold;
	@extend .font_size_26;
	text-align: center;
}

// 共通セクションタイトル
h2.ttl,
h3.ttl {
	@extend %ttl;
}

.z_200 {
	position: relative;
	z-index: 200;
}

// CookiePolicy
.cookie-box {

	z-index: 100;
	position: fixed;
	bottom: 0;
	background-color: rgba(0, 0, 0, 0.6);
	width: 100%;

	.cookie-box_inner {
		display: flex;
		flex-wrap: wrap;
		align-items: center;
		justify-content: space-between;
		margin: 0 auto;
		padding: 20px;
		color: #fff;
		width: 1000px;

		@include mq() {
			width: 90%;
			padding: 15px 0;
		}


		p {
			width: 850px;
			font-size: 12px;
			font-weight: normal;
			line-height: 1.8;

			@include mq() {
				font-size: 10px;
				line-height: 1.6;
			}

			a {
				color: #fff;
				text-decoration: underline;
			}
		}

		button.cookie-box_btn {
			display: inline-block;
			z-index: 1;
			position: relative;
			padding: 15px 20px;
			background: #fff;
			color: #000;
			font-size: 12px;
			font-weight: bold;
			line-height: 1.6;
			text-align: center;
			cursor: pointer;
			border: 2px solid #333;

			@include mq() {
				width: 50vw;
				margin: 5px auto 0;
				padding: 5px 20px;
			}

		}

	}
}


// 背景


// テキスト