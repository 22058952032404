@charset "utf-8";

// ------------ FORM BASS ------------
input,
select,
textarea,
search {
	font-family: $base-font-family;
	border: $border-color 1px solid;
	background-color: #FFF;
	-webkit-appearance: none;
	font-size: 16px; // SPでは16px以上を指定すること（フォーカス時のズーム防止）
	border-radius: 0;

}

button {
	font-family: $base-font-family;
	background-color: transparent;
	@extend .font_size_16;
	border: none;
}

// FOCUS
input:focus,
select:focus,
textarea:focus,
button:focus {
	outline: 0;
	border-color: $main-color;
}

// PLACEHOLDER
:-ms-input-placeholder {
	color: #ddd;
}

::placeholder {
	color: #ddd;
}


// Chorme
::selection {
	color: #fff;
	background: $main-color;
}

input::selection,
textarea::selection {
	color: #fff;
	background: $main-color;
}


// ------------ INPUT STYLE ------------

input {
	width: 100%;
	padding: 10px;
	margin: 5px 0;
}


// 長さ設定
input.wth_80 {
	width: 80%;

	@include mq() {
		width: 100%;
	}
}

input.wth_70 {
	width: 70%;

	@include mq() {
		width: 100%;
	}
}

input.wth_50 {
	width: 50%;

	@include mq() {
		width: 100%;
	}
}

input.wth_30 {
	width: 30%;

	@include mq() {
		width: 100%;
	}
}

input.wth_20 {
	width: 20%;

	@include mq() {
		width: 50%;
	}
}


// テキストエリア
textarea {
	width: 100%;
	height: 10em;
	padding: 10px;
	resize: vertical;
}


// セレクト
select {
	width: auto;
	padding: 10px 60px 10px 10px;
	background-image: url(../img/arrow/input_select.svg);
	background-position: right 5px center;
	background-size: 25px 25px;
	vertical-align: middle;
	border: $border-color 1px solid;
	margin: 5px 0;
	border-radius: 0;
	-webkit-appearance: none;
	-moz-appearance: none;
	appearance: none;
}

// IE
select::-ms-expand {
	display: none;
}


// ラジオボタン チェックボックス
input[type="radio"],
input[type="checkbox"] {
	display: none;
}

// 未選択時
input[type="radio"]+label {
	padding: 7px 0px 7px 25px;
	margin: 2px 20px 2px 0px;
	display: inline-block;
	background-image: url(../img/arrow/input_check_off.svg);
	background-position: left 50%;
	background-size: 18px 18px;
	cursor: pointer;
}

// 選択時
input[type="radio"]:checked+label {
	background-image: url(../img/arrow/input_check_on.svg);
	background-position: left 50%;
	background-size: 18px 18px;
}


// 未選択時
input[type="checkbox"]+label {
	padding: 7px 0px 7px 25px;
	margin: 2px 20px 2px 0px;
	background-image: url(../img/arrow/checkbox_off.svg);
	background-position: left 50%;
	background-size: 18px 18px;
	display: inline-block;
	cursor: pointer;
}

// 選択時
input[type="checkbox"]:checked+label {
	background-image: url(../img/arrow/checkbox_on.svg);
	background-position: left 50%;
	background-size: 18px 18px;
}


// ファイルアップローダ
input[type="file"] {
	border: none;
	padding: 0;
}


// 送信ボタン
input[type="submit"],
input[type="reset"],
input[type="button"],
button[type="submit"],
button[type="button"] {

	&.input_submit {
		display: inline-block;
		width: auto;
		min-width: 260px;
		text-align: center;
		padding: 20px 20px;
		margin: 0 auto;
		font-weight: bold;
		border-radius: 10px;
		color: #FFF;
		background-color: $main-color;

		border: none;
		cursor: pointer;

		&:hover {
			opacity: 0.8;
		}
	}

	&.button_confirm {
		display: inline-block;
		width: auto;
		min-width: 260px;
		text-align: center;
		padding: 20px 20px;
		margin: 0 auto;
		font-weight: bold;
		border-radius: 10px;
		color: #FFF;
		background-color: $main-color;

		border: none;
	}


	&.input_return {
		display: inline-block;
		width: auto;
		border: none;
		padding: 0 0 0 15px;
		background-image: url(../img/arrow/arrow_left.svg);
		background-size: 5px auto;
		background-position: left center;
		min-width: inherit;
		color: $base-font-color;
		font-weight: 500;
		text-decoration: underline;
		background-color: transparent;
		transition: none;
		cursor: pointer;

		@include mq(ssp) {
			width: auto;
		}

	}
}


p#fm_ajax_message {
	font-weight: bold;
	color: $main-color;
	letter-spacing: 1px;
	@extend .font_size_14;
}