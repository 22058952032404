@charset "utf-8";

// ------------ FRONT ------------

.lp_cnt {
	img {
		width: 100%;
		height: auto;
	}

	.cv_simulation {
		width: 72%;
		max-width: 300px;
		margin: 0 auto;
		margin-bottom: -20px;
		margin-bottom: -14px;
	}

	.btn {
		margin-bottom: 15px;

		a {
			display: block;
			color: #fff;
			font-size: 21px;
			font-weight: bold;
			max-width: 350px;
			text-align: center;
			width: 90%;
			margin: 0 auto;
			background-color: #FF7F00;
			border-radius: 100px;
			box-shadow: 0 8px 0 0 #b26200;
			padding: 16px 13px 18px;

			@include mq(ssp) {
				font-size: 20px;
			}
		}
	}

	a.tel {
		display: inline-block;
		color: #fff;
		font-size: 15px;
		line-height: calc(22 / 15);
		text-decoration: underline;
		padding: 1px 5px 1px 28px;
		background-image: url("../img/icon_tel.svg");
		background-repeat: no-repeat;
		background-size: 23px 23px;
		background-position: center left;
	}
}

.cnt_fix_cv_area {
	background-color: rgba($color: $main-color, $alpha: .9);
	position: fixed;
	bottom: 0;
	right: 0;
	left: 0;
	z-index: 100;
	padding: 20px 0;
	border-top: 1px solid $box;
	text-align: center;
}


.top_view {
	background-color: #EAF8FF;

	.inner {
		max-width: 820px;
		margin: 0 auto;
	}

	.cnt_topcv_area {
		padding: 24px 0 30px;
		background-color: #214484;
		text-align: center;

		@include mq() {
			padding: 20px 0 30px;
		}

		p {
			color: #FFEA00;
			font-size: 16px;
			font-weight: bold;
			line-height: calc(17 / 14);
			text-align: center;
			margin-bottom: 5px;

			@include mq() {
				font-size: 14px;
			}
		}
	}
}

.cnt_anxiety {
	padding: 50px 0 0;
	background-color: #EBEBEB;

	@include mq() {
		padding: 30px 0 0;
	}

	.cnt_anxiety__ttl {
		font-weight: bold;
		font-size: 36px;
		text-align: center;
		margin-bottom: 7px;

		@include mq(sp) {
			font-size: 20px;
		}

		em {
			color: $main-color;
			background-image: radial-gradient(circle at center, #FF7F00 20%, transparent 20%);
			background-position: top right;
			background-repeat: repeat-x;
			background-size: 1em 0.3em;
			padding-top: .2em;
		}
	}

	.image_box {
		max-width: 900px;
		margin: 0 auto;
	}
}

.cnt_possibility {
	.inner {
		padding-top: 55px;
		padding-bottom: 52px;
		background-color: #C5CCD0;
		background-image: url("../img/cnt_possibility_bg_pc.png");
		background-position: center center;
		background-size: 100% auto;
		padding-top: 4vw;
		clip-path: polygon(0% 0%, 100% 0, 100% 82%, 50% 100%, 0 82%);
		object-fit: cover;

		@include mq() {
			padding-top: 45px;
			padding-bottom: 73px;
			background-position: top center;

			background-image: url("../img/cnt_possibility_bg_sp.png");
		}

		text-align: center;

		.cnt_possibility__ttl {
			font-size: 28px;
			font-weight: bold;
			margin-bottom: 10px;

			@include mq(sp) {
				font-size: 5.3vw;
			}
		}

		strong {
			font-size: 24px;

			@include mq(sp) {
				font-size: 18px;
			}
		}

		p {
			font-weight: bold;
			font-size: 2.5vw;
			line-height: 1.4;

			@include mq() {
				font-size: 3.5vw;
			}

			@include mq(sp) {
				font-size: 5.5vw;
			}

			em {
				background-size: 100% auto;
				background-position: bottom center;
				background-image: url("../img/bg_line_dot.png");
			}
		}

		.cnt_possibility__list {
			margin: 0 auto;
			display: flex;
			flex-wrap: wrap;
			justify-content: space-between;
			max-width: 770px;
			width: 93%;

			li {
				text-align-last: left;
				line-height: 1.2;
				margin-bottom: 20px;
				width: 49%;
				height: 50px;
				background-color: #fff;
				border-radius: 5px;
				background-image: url("../img/icon_check.png");
				background-size: 26px 26px;
				background-repeat: no-repeat;
				background-position: left 15px center;
				font-size: 19px;
				padding: 0 10px 0 50px;
				display: flex;
				align-items: center;

				@include mq() {
					width: 100%;
					height: 45px;
					font-size: 16px;
					padding: 0 5px 0 56px;
					background-size: 24px 24px;
					margin-bottom: 13px;
					display: flex;
					align-items: center;

				}
			}

		}
	}


	.cnt_mitigation {
		padding: 4vw 0 4vw;
		text-align: center;

		@include mq(sp) {
			padding: 4vw 0 40px;
		}

		.cnt_mitigation__ttl {
			font-size: 28px;
			line-height: 0.8;

			@include mq(sp) {
				font-size: 4.6vw;
			}

			em {
				font-size: 54px;
				color: $main-color;
				font-weight: bold;

				@include mq(sp) {
					font-size: 8.5vw;
				}
			}

			strong {
				font-size: 54px;
				color: $sub-color;
				font-weight: bold;

				@include mq(sp) {
					font-size: 8.5vw;
				}
			}

			span {
				display: inline-block;
				background: linear-gradient(transparent 50%, #FFEA00 50%);
				margin-bottom: 10px;
			}

		}
	}

}



.cnt_cv {
	background-color: $main-color;
	padding: 50px 0;
	text-align: center;

	@include mq() {
		padding: 40px 0;
	}

	.cnt_cv__ttl {
		font-size: 19px;
		line-height: 1.4;
		color: #fff;
		text-align: center;
		background-image: url("../img/cv_text_bg.png");
		background-size: 400px auto;
		background-position: center center;
	}

	.cnt_cv__img {
		max-width: 600px;
		margin: 0 auto 20px;

		@include mq() {
			margin: 0 auto -6px;

		}
	}

	.btn {
		width: 100%;
	}

}

.cnt_method {
	background-color: #D3F1FF;
	padding: 50px 0;

	.cnt_method__ttl {
		color: $main-color;
		font-size: 32px;
		text-align: center;
	}

	.image_box {
		max-width: 770px;
		margin: 35px auto 0;

		@include mq() {
			margin: 35px 20px 0;
		}
	}

	p {
		text-align: center;
		font-size: 19px;

		@include mq() {
			font-size: 16px;
		}
	}
}

.cnt_merit {
	padding: 50px 0 0;

	.cnt_merit__ttl {
		color: $main-color;
		font-size: 32px;
		text-align: center;
	}

	.cnt_merit__list {
		margin: 50px auto;
		display: flex;
		flex-wrap: wrap;
		justify-content: space-between;
		max-width: 1100px;
		width: 90%;

		li {
			width: 32%;
			border: 5px solid $main-color;
			padding: 20px 10px;
			position: relative;
			border-radius: 5px;

			@include mq() {
				width: 100%;
				margin-bottom: 40px;
			}

			.point {
				width: 70px;
				position: absolute;
				top: -35px;
				left: 0;
				right: 0;
				margin: 0 auto;

			}

			.image_box {
				margin-top: 30px;
				margin-bottom: 10px;
				height: 62px;

				img {
					width: auto;
					height: 100%;
					text-align: center;
					margin: 0 auto;
				}
			}

			h3 {
				color: $main-color;
				text-align: center;
				font-size: 20px;
				font-weight: bold;
				margin-bottom: 5px;
				height: 3em;
				display: flex;
				justify-content: center;
				align-items: center;

				@include mq() {
					height: auto;
					display: block;
				}
			}

			p {
				text-align: center;
				font-weight: 400;
			}

			.hide_1150_768 {
				@media screen and (max-width:1150px) {
					display: none;
				}

				@include mq() {
					display: block;
				}
			}
		}
	}
}

.cnt_reduction {
	width: 90%;
	max-width: 1100px;
	margin: 100px auto 70px;

	@include mq() {
		margin: 0 auto 40px;
	}

	.cnt_reduction__ttl {
		color: $main-color;
		text-align: center;
		font-size: 32px;
	}

	.cnt_reduction__list {
		display: flex;
		flex-wrap: wrap;
		justify-content: space-between;

		li {
			margin-top: 20px;
			background-color: $box;
			border-radius: 0 0 5px 5px;
			width: 32%;

			@include mq() {
				width: 100%;
			}

			h3 {
				background-color: $main-color;
				text-align: center;
				color: #fff;
				font-size: 18px;
				padding: 3px;
				letter-spacing: 1px;
				border-radius: 5px 5px 0 0;
			}

			.people_box {
				padding: 10px 20px;
				display: flex;
				align-items: center;
				justify-content: space-between;

				.image_box {
					width: 20%;
				}

				.text_box {
					width: 75%;
					font-size: 16px;
					font-weight: 400;
				}
			}

			h4 {
				color: $main-color;
				font-size: 18px;
				font-weight: 500;
				padding: 0 20px;
				min-height: 107px;

				@include mq() {
					min-height: initial;
				}
			}


			.image_area {
				padding: 10px 20px;
			}

			p {
				padding: 10px 20px 30px;
				font-size: 16px;
			}
		}
	}

	.caption {
		margin-top: 20px;
		font-weight: 400;
		text-align: center;

		@include mq() {
			text-align: left;
		}
	}
}

.cnt_faq {
	padding: 70px 0;

	@include mq() {
		padding: 40px 0;
	}

	.cnt_faq__ttl {
		font-size: 34px;
		color: $main-color;
		text-align: center;
	}

	dl.faq {
		max-width: 800px;
		margin: 0 auto;
		width: 90%;

		text-align: left;

		p {
			display: inline;

			@include mq() {
				display: block;
			}
		}

		span {
			display: inline-block;
			text-align: center;
			color: #76C5FF;
			padding: 0 15px 0 20px;

			border-radius: 100px;
			font-size: 22px;

		}

		dt {
			color: $main-color;
			margin-top: 30px;
			font-weight: 500;
			padding: 8px 0;
			background-color: $box;
			border-radius: 5px;

			display: flex;
			align-items: center;

			p {
				padding: 0 15px 0 0;
				font-size: 16px;
			}
		}

		dd {
			line-height: 1.5em;

			div.detail {
				padding: 10px 20px 10px 0;
				display: flex;

				span {
					display: block;
				}

				p {
					font-weight: 500;
					font-size: 16px;
				}
			}
		}

	}
}

.content_wrap {
	height: 120px;
	overflow: hidden;
	position: relative;
	margin: 0;
}

.close_btn,
.more_btn {
	display: block;
	width: 100%;
	padding: 80px 0 30px;
	position: absolute;
	bottom: 0;
	left: 0;
	text-align: center;
	background: -moz-linear-gradient(top,
			rgba(255, 255, 255, 0) 0%,
			rgb(230, 247, 255) 60%);
	background: -webkit-linear-gradient(top,
			rgba(255, 255, 255, 0) 0%,
			rgb(230, 247, 255) 60%);
	background: linear-gradient(to bottom,
			rgba(255, 255, 255, 0) 0%,
			rgb(230, 247, 255) 60%);
	filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='$box',
			endColorstr='$box',
			GradientType=0);
}

.close_btn {
	background: none;
}

.slide_up {
	height: 150px;
	padding-bottom: 0;
	overflow: hidden;
}

.slide_down {
	height: auto;
	overflow: visible;
	padding-bottom: 50px;
}

.more_btn p {
	display: inline-block;
	color: #fff;
	background-color: $main-color;
	cursor: pointer;
	padding: 5px 24px !important;
	border-radius: 20px;
}

.close_btn {
	padding: 0 0 24px !important;
}