@charset "UTF-8";

// ---- テーマの基本スタイル ----

// メインカラー
$main-color: #214484;
$sub-color: #FF7F00;
$err-color: #de2636; // フォームのエラー表示の色として設定

// 標準背景色
$bg-color: #EBEBEB; // フッターやセクションの背景色としてよく使用する色を設定

// 標準フォントカラー
$base-font-color: #000000;

// フォント指定
// --- ゴシック体 ---
@import url('https://fonts.googleapis.com/css2?family=M+PLUS+Rounded+1c:wght@400;500;700&display=swap');

$base-font-family: 'M PLUS Rounded 1c', "ヒラギノ角ゴ Pro", "Hiragino Kaku Gothic Pro", "游ゴシック体", "Yu Gothic", YuGothic, "メイリオ", "Meiryo", 'Roboto', sans-serif;

// 標準ボーダーカラー
$border-color: #CCCCCC; // フォームのテーブル、hrなどのボーダーカラー
$btn-color: #E1F7C7;

// ボックス
$box: #E6F7FF; // フォームのテーブルthの背景色など

// 行間（_reset.scssで使用）
$base-line-height: 1.5em;