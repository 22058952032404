@charset "utf-8";

// ------------ COMMON HEADER ------------

header {
	width: 100%;
	background-color: #fff;

	div.inner {
		min-width: 1000px;
		height: 80px;
		margin: auto;
		position: relative;

		@include mq() {
			min-width: inherit;
			height: 55px;
		}
	}

	// Logo
	h1,
	div.header_logo {
		@extend .vertical;
		margin-left: 20px;

		@include mq() {
			margin-left: 10px;
		}

		a {
			display: block;

			img {
				width: 210px !important;
				height: auto !important;
			}
		}
	}

	// CV
	div.cv {
		width: 180px;
		height: 100%;
		@include abs-position(0px, 0px, auto, auto);

		background-color: $main-color;
		color: #FFEA00;
		font-size: 30px;
		text-align: left;
		padding: 10px;
		line-height: 1;

		@include mq() {
			width: 130px;
			font-size: 20px;
		}

		span {
			display: block;
			font-size: 16px;

			@include mq() {
				font-size: 10px;
			}
		}

		em {
			font-size: 16px;

			@include mq() {
				font-size: 10px;
			}
		}

		span.asterisk {
			font-size: 10px;
			position: absolute;
			top: 8px;
			right: 50px;

			@include mq() {
				right: 40px;
			}
		}
	}
}

// ------------　スクロール後FIXED設定 ------------

header.fixed {
	background: rgba(255, 255, 255, .7);
	position: fixed;
	z-index: 100;
}