@charset "utf-8";

// ------------ BASS LAYOUT ------------

// パンくずリスト
div.pan {
	width: 100%;
	height: 3.7em;
	margin: 0;
	padding: 1em 0;
	background-color: #fff;
	border-top: $border-color 1px solid;

	position: relative;
	@extend .font_size_12;

	overflow-x: auto;
	overflow-y: hidden;
	-webkit-overflow-scrolling: touch;
	overflow-scrolling: touch;

	div.in {
		@extend .wrapper;
	}


	#breadcrumbs {
		margin: auto;
		white-space: nowrap;

		a {
			color: $main-color;
			background-image: url(../img/arrow/arrow_right_c.svg);
			background-position: center right 5px;
			background-size: 13px 13px;
			padding: 0 22px 0 0;
			font-weight: bold;
		}

		img {
			margin: 1px 5px;
			vertical-align: text-bottom;
			width: 14px;
			height: 13px;
		}
	}
}


// ------------ CONTENT STYLE ------------

// よくあるご質問



// メッセージボックス（フォームの送信完了画面と404ページで使用）
div.basket_box {
	max-width: 700px;
	height: auto;
	margin: auto;
	text-align: center;
	padding: 50px 0;

	@include mq(sp) {
		padding: 50px 0;
	}

	h1,
	h2 {
		padding: 0 0 20px;
		margin: 0 0 20px 0;
		text-align: center;
		font-weight: bold;
		@extend .font_size_30;

		&:before {
			background-color: $main-color;
		}
	}

	p {
		padding: 0 0 30px 0;
		font-weight: bold;
		line-height: 1.6;
		@extend .font_size_16;
	}
}


// 404ページ
div.n_found {

	h1,
	h2 {
		color: $main-color;
	}
}

// 完了ページ
div.f_complete {
	h1 {
		color: #FF7F00;
		font-size: 21px !important;
		font-weight: bold;
	}

	p {
		font-weight: 500;
	}
}